#questionnaire, #poster {
  background-color: black;

  .container,
  .container_fluid{
    //height: 50%;

    .row{

      .col {
        text-align: center;

        .text_holder {
          max-width: 100%;
          margin-left: 0%;

          @include media-breakpoint-up(sm) {

            max-width: 80%;
            margin-left: 10%;
          }
          @include media-breakpoint-up(md) {

            max-width: 80%;
            margin-left: 10%;
          }
        }

        h1 {
          padding-bottom: 50px;
          font-weight: 800;
        }



      }
    }
  }
}