#profile {

  .container,
  .container_fluid{

    .row {
      //height: 100%;

      .col {
        //text-align: center;

        h1 {
          padding-bottom: 50px;
        }
      }
    }
  }
}