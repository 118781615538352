#intro {
  background-color: black;

  .container {
    //height: 100vh;

    .row{

      .col {
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .language_holder {
          padding-top: 20px;
          padding-bottom: 20px;

          p, a {
            display: inline-block;
          }

          @include media-breakpoint-up(sm) {
            padding-top: 20px;
            padding-bottom: 20px;
          }
          @include media-breakpoint-up(md) {
            padding-top: 30px;
            padding-bottom: 30px;
          }
          @include media-breakpoint-up(lg) {
            padding-top: 30px;
            padding-bottom: 30px;
          }
          @include media-breakpoint-up(xl) {
            padding-top: 40px;
            padding-bottom: 40px;
          }
        }

        .intro_logo {
          max-width: 100px;
          width: 50%;

          img {
            width: 100%;
          }
        }

        .text_holder {
          margin-left: 0;

          .intro_image {
            margin: 75px 0;

            img {
              width: 100%;
            }
          }

          .intro_buttons {
            display: flex;
            justify-content: center;
            flex-direction: row;

            .intro_button{
              cursor: pointer;
              margin: 0 30px;
              margin-top: 50px;

              .link_icon {
                margin: 0 auto;
                width: 60px !important;
                padding-bottom: 20px;
                opacity: .5;
                transition: 0.5s;
              }
              .link_grafic {
                margin: 0 auto;
                width: 100px !important;
                padding-bottom: 30px;
                opacity: 1;
                transition: 0.5s;
              }
              .link_text {
                letter-spacing: 5px;
                font-size: 30px;
                font-style: italic;

                @include media-breakpoint-up(sm) {
                  font-size: 40px;
                }
              }

              &:hover {
                .link_icon {
                  opacity: 1;
                }
              }
            }
          }

          a {
            margin-top: 50px;
            display: block;
          }
        }
      }
    }
  }
}