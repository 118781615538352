#footer {
  background-color: black;
  padding-top: 50px;
  padding-bottom: 50px;

  .container,
  .container_fluid{

    .row{

      .col {
        display: flex;
        align-items: center;

        .link_holder {
          width: 100%;
          text-align: center;
          //border: 1px solid red !important;

          @include media-breakpoint-up(sm) {
            //text-align: left;
          }
        }
        .socialmedia_holder {
          width: 100%;
          text-align: center;
          //border: 1px solid red !important;

          @include media-breakpoint-up(sm) {
            //text-align: right;
          }
          .socialmedia_icon {
            display: inline-block;
          }
        }
      }
    }
  }
}
