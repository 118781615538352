/* bodoni-moda-regular - latin */
@font-face {
  font-family: 'Bodoni Moda';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/bodoni-moda-v19-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('./fonts/bodoni-moda-v19-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('./fonts/bodoni-moda-v19-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('./fonts/bodoni-moda-v19-latin-regular.woff') format('woff'), /* Modern Browsers */
  url('./fonts/bodoni-moda-v19-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('./fonts/bodoni-moda-v19-latin-regular.svg#BodoniModa') format('svg'); /* Legacy iOS */
}

/* bodoni-moda-500 - latin */
@font-face {
  font-family: 'Bodoni Moda';
  font-style: normal;
  font-weight: 500;
  src: url('./fonts/bodoni-moda-v19-latin-500.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('./fonts/bodoni-moda-v19-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('./fonts/bodoni-moda-v19-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
  url('./fonts/bodoni-moda-v19-latin-500.woff') format('woff'), /* Modern Browsers */
  url('./fonts/bodoni-moda-v19-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
  url('./fonts/bodoni-moda-v19-latin-500.svg#BodoniModa') format('svg'); /* Legacy iOS */
}

/* bodoni-moda-600 - latin */
@font-face {
  font-family: 'Bodoni Moda';
  font-style: normal;
  font-weight: 600;
  src: url('./fonts/bodoni-moda-v19-latin-600.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('./fonts/bodoni-moda-v19-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('./fonts/bodoni-moda-v19-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
  url('./fonts/bodoni-moda-v19-latin-600.woff') format('woff'), /* Modern Browsers */
  url('./fonts/bodoni-moda-v19-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
  url('./fonts/bodoni-moda-v19-latin-600.svg#BodoniModa') format('svg'); /* Legacy iOS */
}

/* bodoni-moda-700 - latin */
@font-face {
  font-family: 'Bodoni Moda';
  font-style: normal;
  font-weight: 700;
  src: url('./fonts/bodoni-moda-v19-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('./fonts/bodoni-moda-v19-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('./fonts/bodoni-moda-v19-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
  url('./fonts/bodoni-moda-v19-latin-700.woff') format('woff'), /* Modern Browsers */
  url('./fonts/bodoni-moda-v19-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
  url('./fonts/bodoni-moda-v19-latin-700.svg#BodoniModa') format('svg'); /* Legacy iOS */
}

/* bodoni-moda-800 - latin */
@font-face {
  font-family: 'Bodoni Moda';
  font-style: normal;
  font-weight: 800;
  src: url('./fonts/bodoni-moda-v19-latin-800.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('./fonts/bodoni-moda-v19-latin-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('./fonts/bodoni-moda-v19-latin-800.woff2') format('woff2'), /* Super Modern Browsers */
  url('./fonts/bodoni-moda-v19-latin-800.woff') format('woff'), /* Modern Browsers */
  url('./fonts/bodoni-moda-v19-latin-800.ttf') format('truetype'), /* Safari, Android, iOS */
  url('./fonts/bodoni-moda-v19-latin-800.svg#BodoniModa') format('svg'); /* Legacy iOS */
}

/* bodoni-moda-500italic - latin */
@font-face {
  font-family: 'Bodoni Moda';
  font-style: italic;
  font-weight: 500;
  src: url('./fonts/bodoni-moda-v19-latin-500italic.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('./fonts/bodoni-moda-v19-latin-500italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('./fonts/bodoni-moda-v19-latin-500italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('./fonts/bodoni-moda-v19-latin-500italic.woff') format('woff'), /* Modern Browsers */
  url('./fonts/bodoni-moda-v19-latin-500italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('./fonts/bodoni-moda-v19-latin-500italic.svg#BodoniModa') format('svg'); /* Legacy iOS */
}

/* bodoni-moda-600italic - latin */
@font-face {
  font-family: 'Bodoni Moda';
  font-style: italic;
  font-weight: 600;
  src: url('./fonts/bodoni-moda-v19-latin-600italic.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('./fonts/bodoni-moda-v19-latin-600italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('./fonts/bodoni-moda-v19-latin-600italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('./fonts/bodoni-moda-v19-latin-600italic.woff') format('woff'), /* Modern Browsers */
  url('./fonts/bodoni-moda-v19-latin-600italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('./fonts/bodoni-moda-v19-latin-600italic.svg#BodoniModa') format('svg'); /* Legacy iOS */
}

/* bodoni-moda-700italic - latin */
@font-face {
  font-family: 'Bodoni Moda';
  font-style: italic;
  font-weight: 700;
  src: url('./fonts/bodoni-moda-v19-latin-700italic.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('./fonts/bodoni-moda-v19-latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('./fonts/bodoni-moda-v19-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('./fonts/bodoni-moda-v19-latin-700italic.woff') format('woff'), /* Modern Browsers */
  url('./fonts/bodoni-moda-v19-latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('./fonts/bodoni-moda-v19-latin-700italic.svg#BodoniModa') format('svg'); /* Legacy iOS */
}

/* bodoni-moda-800italic - latin */
@font-face {
  font-family: 'Bodoni Moda';
  font-style: italic;
  font-weight: 800;
  src: url('./fonts/bodoni-moda-v19-latin-800italic.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('./fonts/bodoni-moda-v19-latin-800italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('./fonts/bodoni-moda-v19-latin-800italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('./fonts/bodoni-moda-v19-latin-800italic.woff') format('woff'), /* Modern Browsers */
  url('./fonts/bodoni-moda-v19-latin-800italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('./fonts/bodoni-moda-v19-latin-800italic.svg#BodoniModa') format('svg'); /* Legacy iOS */
}




//! set font variables
$font-heading: "Bodoni Moda", "Georgia", serif;
$font-body: "Bodoni Moda", "Georgia", serif;

html {
  font-size: 62.5%; // 1rem = 10px, 3.5rem = 35px;
}

body { 
  font-family: $font-body;
  font-size: 1.6rem;
  color: $black;
}

h1 {
  font-family: $font-heading, serif;
  font-size: 30px;
  font-weight: 400;
  color: white;
  margin: 0;
  padding-bottom: 50px;
  letter-spacing: 8px;
  //border: 1px solid red;

  @include media-breakpoint-up(sm) {
    font-size: 30px;
  }
  @include media-breakpoint-up(md) {
    font-size: 35px;
  }
  @include media-breakpoint-up(lg) {
    font-size: 40px;
  }
  @include media-breakpoint-up(xl) {
    font-size: 40px;
  }

  &.big {
    font-size: 40px;
    font-weight: 400;

    @include media-breakpoint-up(sm) {
      font-size: 55px;
    }
    @include media-breakpoint-up(md) {
      font-size: 65px;
    }
    @include media-breakpoint-up(lg) {
      font-size: 75px;
    }
    @include media-breakpoint-up(xl) {
      font-size: 80px;
    }
  }
}
h2, .language {
  font-family: $font-heading, serif;
  font-size: 26px;
  font-weight: 400;
  color: white;
  margin: 0;
  padding-top: 5px;
  padding-bottom: 25px;
  letter-spacing: 8px;

  &.no_spacing {
    letter-spacing: 0px;
  }

  &.small {
    font-size: 20px;
  }

  &.language_spacer {
    font-size: 35px;
  }
}
h3 {
  font-family: $font-heading, serif;
  font-size: 24px;
  font-weight: 400;
  color: white;
  margin: 0;
  padding-bottom: 30px;
}
p, a {
  font-family: $font-body;
  font-size: 16px;
  font-weight: 700;
  font-weight: 100;
  color: white;
  margin: 0;
  padding-bottom: 20px;

  @include media-breakpoint-up(sm) {
    font-size: 18px;
  }
  @include media-breakpoint-up(md) {
    font-size: 20px;
  }
  @include media-breakpoint-up(lg) {
    font-size: 20px;
  }
  @include media-breakpoint-up(xl) {
    font-size: 20px;
  }

  &.big {
    //font-size: 40px;
  }
  &.middle {
    //font-size: 30px;
    font-weight: 100;
  }
  &.light {
    font-weight: 400;
  }
  &.nav-link {
    font-size: 20px;
   line-height: 18px;
  }
  &.poster_holder {
    text-align: center;

    .poster_image{
      margin: 0 auto;
      //width: 100px !important;
      padding-bottom: 30px;
      opacity: 1;
      transition: 0.5s;
    }
    .poster_text{
      letter-spacing: 5px;
      font-size: 30px;
      font-style: italic;

      @include media-breakpoint-up(sm) {
        font-size: 40px;
      }
    }
  }
  &.form_button {
    cursor: pointer;

    .link_icon {
      margin: 0 auto;
      width: 100px !important;
      padding-bottom: 30px;
      opacity: 0.5;
      transition: 0.5s;
    }
    .link_grafic {
      margin: 0 auto;
      width: 100px !important;
      padding-bottom: 30px;
      opacity: 1;
      transition: 0.5s;
    }
    .link_text {
      letter-spacing: 5px;
      font-size: 30px;
      font-style: italic;

      @include media-breakpoint-up(sm) {
        font-size: 40px;
      }
    }

    &:hover {
      .link_icon {
        opacity: 1;
      }
    }
  }
  &.profile_copy {
    letter-spacing: 2px;
    font-weight: 500;

    &.bold_kursiv{
      font-style: italic !important;
      font-weight: 800 !important;
    }

  }

  &.language {

  }
}

.tour_text_holder{

  .tour_holder{
    text-align: center;

    .tour_image {
      margin: 0 auto;
      padding-bottom: 30px;
      opacity: 1;
      transition: 0.5s;
    }

    p {
      letter-spacing: 5px;
      font-size: 30px;
      font-style: italic;

      @include media-breakpoint-up(sm) {
        font-size: 40px;
      }
    }
  }



}

a:hover {
  color: white;
  text-decoration: underline;
}


.button_holder {
  text-align: center;
  padding-bottom: 30px;
}

.verdana {
  font-family: 'Verdana', sans-serif;
}