#feedback {

  .container,
  .container_fluid{
    //height: 50%;

    .row{

      .col {
        text-align: center;

        h1 {
          padding-bottom: 50px;
        }
        h2 {
          letter-spacing: 0px;
          font-weight: 700;
        }
        p {
          font-weight: 100;
          font-style: normal;
        }
      }
    }
  }
}