.modal-dialog {

  .modal-content{
    background-color: black;

    .modal-body {
      position:relative;
      padding: 60px 0;
      padding: 0px 0;
      background-color: rgba(000,000,000,.0);
      //display: flex;
      //justify-content: center;
      //align-items: center;
      overflow-x: hidden;

      .container,
      .text_holder {
        position: relative;
        //background-color: white;
        width: 100%;
        height: auto;
        margin: auto;
        padding: 5px;

        @include media-breakpoint-up(sm) {
          padding: 10px;
        }
        @include media-breakpoint-up(md) {
          padding: 20px;
        }
        @include media-breakpoint-up(lg) {
          padding: 30px;
        }
        @include media-breakpoint-up(xl) {
          padding: 40px;
        }

        .modal_text_button_close {
          z-index: 10;
          position: fixed;
          top: 20px;
          right: 20px;
          height: 60px;
          width: 60px;
          border-radius: 30px;
          cursor: pointer;
          margin-right: 0px;
          transition: 1000ms;

          @include media-breakpoint-up(sm) {
            right: 50px;
            top: 50px;
          }

          .burger_holder {
            position: relative;
            width: 40px;
            height: 26px;
            margin-left: 15px;
            margin-top: 18px;
            transition: .5s;

            span{
              background: white;
              border: none;
              height: 3px;
              width: 100%;
              width: 0;
              position: absolute;
              top: 0;
              left: 0;
              transition: all 0.35s ease;
              cursor: pointer;
              border-radius: 0px;
              transform-origin: center center;

              &.top {
                transform: translateY(11px) translateX(0) rotate(45deg);
                background: white;
                left: 0;
                width: 100%;
              }

              &.bottom {
                transform: translateY(-11px) translateX(0) rotate(-45deg);
                background: white;
                top: 22px;
                left: 0;
                width: 100%;
              }
            }


            &:hover{
              transform: rotate(90deg);
            }
          }
        }

        img {
          width: 200px;
        }

        h2 {
          color: white;
          margin-left: 0px !important;
        }
        h3 {
          color: white;
          padding-top: 30px;
          padding-bottom: 10px;
        }
        p {
          color: white;
        }

        .row {

          .col {
            display: flex;
            //justify-content: center;
            align-items: flex-start;

            form {

              // ERROR HIGHLIGHT
              .was-validated .form-control:invalid,
              .form-control.is-invalid,
              .was-validated .custom-select:invalid,
              .custom-select.is-invalid {
                border-color: #fb7402 !important;
              }

              .invalid-feedback,
              .error{
                color: #fb7402 !important;
                font-size: 12px !important;
              }

              label {
                $font-body: "PT Serif", "Georgia", serif;
                width: 100%;
                color: white;
                font-size: 20px;

                &.headline {
                  padding-bottom: 10px;
                }
              }

              .form-group {
                position: relative;
                padding-bottom: 30px;

                .form-control {

                  &::placeholder {
                    color: rgba(255, 255, 255, .3);
                    font-size: 20px;
                  }
                }

                select,
                input {
                  margin-bottom: 5px;
                  width: 100%;
                  color: white;
                  font-size: 20px;
                  outline: 0;
                  border-width: 0 0 1px;
                  border-width: 1px;
                  border-color: rgba(255, 255, 255, .5);
                  border-radius: 0;
                  background-color: transparent;
                }
                textarea{
                  outline: 0;
                  height: 100px;
                  color: white;
                  font-size: 20px;
                  border-width: 0 0 1px;
                  border-color: white;
                }

                .button_form_send {
                  margin-top: 30px;
                  cursor: pointer;
                  display: inline-block;
                  display: block;
                  width: 160px;
                  min-height: 36px;
                  background-color:white;
                  border: 1px solid white;

                  -webkit-border-radius: 0px;
                  -moz-border-radius: 0px;
                  border-radius: 0px;
                  color: black;
                }
                .button_form_send:hover {
                  background-color: black;
                  border: 1px solid white;
                  color: white;
                }
              }

              .radio-button,
              .checkboxes{
                padding-bottom: 30px;

                label {
                  cursor: pointer;
                  $font-body: "PT Serif", "Georgia", serif;
                  width: 100%;
                  color: white;
                  font-size: 20px;
                }


                input {
                  color: white;
                  margin-right: 10px;
                }

                span {
                  $font-body: "PT Serif", "Georgia", serif;
                  color: white;
                  font-size: 20px;
                  padding-left: 10px;
                  padding-right: 20px;
                }


              }

              #form-messages{
                color: orange;
              }
            }
          }
        }
      }
    }
  }
}


.fade_from_bottom {
  transform: translateY(110%);
  opacity: 1 !important;
  -webkit-transition: .5s opacity, 1s all 0s;
  -moz-transition: .5s opacity, 1s all 0s;
  -ms-transition: .5s opacity, 1s all 0s;
  -o-transition: .5s opacity, 1s all 0s;
  transition: .5s opacity, 1s all 0s;
  display: block !important;
}

.fade_from_bottom.show {
  opacity: 1;
  transform: translateY(0) !important;
  transform:none;
}

.modal-backdrop {
  background-color: transparent;
}