.section {
  position: relative;
  background-color: black;
  overflow-x: hidden;
  box-sizing: border-box;
  //border: 3px solid red;

  &.section_100 {

    @include media-breakpoint-up(md) {
      min-height: 100vh;
    }

  }

  .container,
  .container_fluid{
    //min-height: 100%;
    //padding-top: 100px;
    //padding-bottom: 100px;
    //border: 1px solid rgba(255, 255, 255, .6);
    //border: 1px solid red;

    .row {
      min-height: 100%;

      .col_text {
//min-height: 100vh;
        display: flex;
        align-items: center;

        .text_holder {
          width: 100%;
          max-width: 90%;
          margin-left: 5%;


          @include media-breakpoint-up(sm) {
            max-width: 80%;
            margin-left: 10%;

          }
          @include media-breakpoint-up(md) {
            max-width: 70%;
            margin-left: 15%;
          }
          @include media-breakpoint-up(lg) {
            max-width: 60%;
            margin-left: 20%;
          }
          @include media-breakpoint-up(xl) {
            max-width: 50%;
            margin-left: 25%;
          }

          img {
            width: 100%;
          }
        }
      }

      .col {

        .poster, .tour {
          width: 100%;

          img {
            width: 100%;
          }
        }
      }

      .col_padding_top {
        padding-top: 90px;

        @include media-breakpoint-up(sm) {
          padding-top: 90px;
        }
        @include media-breakpoint-up(md) {
          padding-top: 110px;
        }
        @include media-breakpoint-up(lg) {
          padding-top: 130px;
        }
        @include media-breakpoint-up(xl) {
          padding-top: 150px;
        }
      }
      .col_padding_bottom {
        padding-bottom: 90px;

        @include media-breakpoint-up(sm) {
          padding-bottom: 90px;
        }
        @include media-breakpoint-up(md) {
          padding-bottom: 110px;
        }
        @include media-breakpoint-up(lg) {
          padding-bottom: 130px;
        }
        @include media-breakpoint-up(xl) {
          padding-bottom: 150px;
        }
      }
    }
  }
}

.section_caroussel {
  //border: 1px solid red;
  z-index: 2;
  background-color: black;
  overflow: hidden;
  padding-bottom: 50px;

  @include media-breakpoint-up(md) {
    padding-bottom: 0;
  }

  .container,
  .container_fluid {
    //min-height: 100%;
    padding-top: 0px;
    padding-bottom: 0px;

    .row {

      @include media-breakpoint-up(md) {
        min-height: 100%;
      }

      .col {
       //border: 1px solid red;

        @include media-breakpoint-up(md) {
          min-height: 100%;
        }

        .caroussel {
          width: 100%;
          transform-origin: center top ;

          .caroussel_item,
          .caroussel_item_feedback{
            position: relative;
            display: flex !important;
            align-items: center;
            justify-content: center;
            //border: 1px solid green;
            //min-height: 600px;

            &.image {
              align-items: flex-start;
              //min-height: 600px;
              //border: 1px solid red;

              img {
                width: 100%;
              }
            }

            &.text {
              min-height: 100%;
min-height: 500px;

              //border: 1px solid green;
            }

            .caroussel_item_content {
              color: white;
              font-size: 50px;
              max-width: 100%;
              overflow-x: hidden;

              &.text {
                max-width: 80%;
                //border: 1px solid red;

                @include media-breakpoint-up(sm) {
                  max-width: 80%;
                }
                @include media-breakpoint-up(md) {
                  max-width: 80%;
                }
                @include media-breakpoint-up(lg) {
                  max-width: 60%;
                  max-width: 80%;
                }
                @include media-breakpoint-up(xl) {
                  max-width: 50%;
                  max-width: 80%;
                  }
              }

              &.image {
                height: calc(100% - 50px);
                height: auto;
                width: 100%;
                display: flex;
                align-items: flex-start;
                justify-content: center;

                img {
                  height: auto;
                  width: 100%;
                }
              }
            }
          }

          // feedback
          &.caroussel_height_auto {
            height: auto;

            .caroussel_item {
              height: auto;
              padding-bottom: 50px;
            }
          }

          @include media-breakpoint-up(md) {
            height: 100vh;
            height: calc(100vh - 50px);
min-height: 550px;

            .caroussel_item {
              height: 100vh;
              height: calc(100vh - 50px);
min-height: 550px;

              .caroussel_item_content {

                &.image {
                  height: calc(100% - 50px);
                  height: 100%;
                  width: auto;

                  img {
                    height: 100%;
                    width: auto;
                  }
                }
              }
            }

            .caroussel_item_feedback {
              width: 100% !important;
              padding: 0 10%;
            }
          }
        }

        .caroussel_arrow_left,
        .caroussel_arrow_right{
          cursor: pointer;
          position: absolute;
          top:0;
          width: 20%;
          height: 100%;

          .caroussel_arrow_label {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 60px;
            text-align: center;
            opacity: .5;
            transition: .5s;

            img {
              width: 100%;
            }

            @include media-breakpoint-up(sm) {
              width: 50px;
            }
            @include media-breakpoint-up(md) {
              width: 60px;
            }
            @include media-breakpoint-up(lg) {
              width: 70px;
            }
            @include media-breakpoint-up(xl) {
              width: 80px;
            }
          }

          &:hover {

            .caroussel_arrow_label {
              opacity: 1;
            }
          }
        }
        .caroussel_arrow_left {
          right: auto;
          left: 0px;
        }
        .caroussel_arrow_right {
          left: auto;
          right: 0px;
        }
      }
    }
  }
}

.slick-dots {

  @include media-breakpoint-up(md) {
    //bottom: 15px;
    bottom: -30px;
  }



  li button::before {
    font-size: 12px !important;
    width: 40px;
    height: 40px;
    opacity: .5 !important;
    color: white !important;
  }

  li.slick-active button::before {
    opacity: 1 !important;
    color: white !important;
  }
}
.slick-dotted.slick-slider {
  margin-bottom: 0px;
}
