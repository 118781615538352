#session,
#profile{
  background-color: black;

  .container,
  .container_fluid{
    height: 100%;

    .row{
      //height: 100%;

      .col {
        text-align: center;

        h1 {
          padding-bottom: 10px;
        }
        h2 {
          padding-bottom: 40px;
        }
        p {
          font-weight: 100;
          font-style: normal;
        }
      }

      .col-left_ {
        text-align: right;
        padding-top: 0px;
        padding-bottom: 0px;
        padding-right: 6px;

        @include media-breakpoint-up(sm) {
          padding-right: 7px;
        }
        @include media-breakpoint-up(md) {
          padding-right: 8px;
        }
        @include media-breakpoint-up(lg) {
          padding-right: 10px;
        }
        @include media-breakpoint-up(xl) {
          padding-right: 12px;
        }
      }
      .col-right_ {
        text-align: left;
        padding-top: 0px;
        padding-bottom: 0px;
        padding-left: 6px;

        @include media-breakpoint-up(sm) {
          padding-left: 7px;
        }
        @include media-breakpoint-up(md) {
          padding-left: 8px;
        }
        @include media-breakpoint-up(lg) {
          padding-left: 10px;
        }
        @include media-breakpoint-up(xl) {
          padding-left: 12px;
        }
      }

      .col_padding_bottom {
        padding-top: 50px;
      }
    }

    .row_list {

      .col:nth-child(even) {
        text-align: left;
        padding-top: 0px;
        padding-bottom: 0px;
        padding-left: 6px;

        @include media-breakpoint-up(sm) {
          padding-left: 7px;
        }
        @include media-breakpoint-up(md) {
          padding-left: 8px;
        }
        @include media-breakpoint-up(lg) {
          padding-left: 10px;
        }
        @include media-breakpoint-up(xl) {
          padding-left: 12px;
        }
      }
      .col:nth-child(odd){
        text-align: right;
        padding-top: 0px;
        padding-bottom: 0px;
        padding-right: 6px;

        @include media-breakpoint-up(sm) {
          padding-right: 7px;
        }
        @include media-breakpoint-up(md) {
          padding-right: 8px;
        }
        @include media-breakpoint-up(lg) {
          padding-right: 10px;
        }
        @include media-breakpoint-up(xl) {
          padding-right: 12px;
        }
      }
    }
  }
}