*::selection {
  background: black;
}

html,
body {
  height: 100%;
  min-height: 100vh;
  background: black;
}

html {
  &[data-modal-active="true"] {
    touch-action: none;
    overscroll-behavior: none;
    -webkit-overflow-scrolling: auto;
    overflow: hidden;
  }
}
body {
  position: relative;
  width: 100%;
  margin: 0;
  [data-modal-active="true"] & {
    touch-action: none;
    overscroll-behavior: none;
    -webkit-overflow-scrolling: auto;
    overflow: hidden;
  }
}
.wrapper {
  max-width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

p {
  margin: 0;
}

a {
  text-decoration: none;
}

img {
  display: block;
}

ul {
  padding: 0;
  list-style: none;
}
