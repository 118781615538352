#header,
#video {
  background-color: black;

  .container {
    height: 100vh;

    .row{

      .col {

        .video_holder {
          z-index: 1;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          transition: 0ms;

          background: url("./video/missy_video.jpg");
          background-size: cover;
          background-position: center;

          -webkit-transform-origin: center center;
          -moz-transform-origin: center center;
          -o-transform-origin: center center;
          transform-origin: center center;

          video {
            position: absolute;
            top: 50%;
            left: 50%;
            min-width: 100%;
            min-height: 100%;
            width: auto;
            height: auto;
            z-index: 0;
            transition: 0ms;
            -ms-transform: translateX(-50%) translateY(-50%);
            -moz-transform: translateX(-50%) translateY(-50%);
            -webkit-transform: translateX(-50%) translateY(-50%);
            transform: translateX(-50%) translateY(-50%);
          }

          &.show {
            -ms-transform: scale(1);
            -moz-transform: scale(1);
            -webkit-transform: scale(1);
            transform:  scale(1);

            video {
              -ms-transform: translateX(-50%) translateY(-50%);
              -moz-transform: translateX(-50%) translateY(-50%);
              -webkit-transform: translateX(-50%) translateY(-50%);
              transform: translateX(-50%) translateY(-50%);
            }
          }
        }

        .header_logo_holder {
          z-index: 1;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          max-width: 500px;
          max-width: 750px;
          max-width: 600px;
          width: 50%;
          width: 80%;

          .header_logo {
            pointer-events: none;
            position: relative;
            z-index:2;
            width: 100%;
            transform: translateY(-10%) scale(1);
            transition: 1s;
            opacity: 0;

            img {
              width: 100%;
            }

            &.show {
              opacity: 1;
              transform: translateY(-0%) scale(1);
            }
          }

          .header_stoerer {
            z-index:1;
            position: absolute;
            top: 20px;
            right: 20px;
            transform: scale(.8);
            max-width: 240px;
            max-width: 360px;
            width: 45%;
            transition: 1s;
            opacity: 0;

            img {
              width: 100%;
            }
            &.show {
              opacity: 1;
              transform: scale(1);
            }
            &.hide {
              display: none !important;
            }
          }
        }
      }
    }
  }
}

#header {
  z-index: 1;
}

#video {

  .container-fluid {
    height: auto;

    .row {

      .col {

        .video_holder {
          width: 100%;
          position: relative;
          padding-bottom: 56.25%; /* 16:9 */
          height: 0;
          padding-top: 30px;
          overflow: hidden;

          .content_video {
            min-width: 100%;
            min-height: auto;
            width: 100%;
            height: auto;
          }
        }
      }
    }
  }
}