#faq {
  background-color: black;

  .container,
  .container_fluid{
    height: 100%;

    .row{
      height: 100%;

      .col {
        height: 100%;
        //text-align: center;

        h1 {
          padding-bottom: 50px;
        }

        .faq_holder{
          padding-bottom: 30px;
          text-align: center;

          max-width: 90%;
          margin-left: 5%;

          @include media-breakpoint-up(sm) {
            max-width: 80%;
            margin-left: 10%;
          }
          @include media-breakpoint-up(md) {
            max-width: 70%;
            margin-left: 15%;
          }
          @include media-breakpoint-up(lg) {
            max-width: 60%;
            margin-left: 20%;
          }
          @include media-breakpoint-up(xl) {
            max-width: 50%;
            margin-left: 25%;
          }

          .faq_question {
            cursor: pointer;
            padding-bottom: 10px;

            .icon {
              display: inline-block;
              vertical-align:top;
              width: 20px;
              margin-top: 5px;
              transition: .5s;
              //border: 1px solid red;

              &.rotate {
                transform: rotate(90deg);
              }

              img {
                width: 100%;
              }
            }

            .text {
              //display: inline-block;
              max-width: calc(100%   -  30px);
              //border: 1px solid red;
            }
          }

          .faq_answer {
            max-height: 0;
            overflow: hidden;
            transition: 1s;
          }

        }
      }
    }
  }
}
