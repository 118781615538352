#about {
  background-color: black;

  .container,
  .container_fluid{
    //height: 50%;

    .row{

      .col {
        text-align: center;

        h1 {
          padding-bottom: 50px;
        }

      }
    }
  }
}